body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.login-link,
.login-link:hover,
.login-link:focus {
  color: white;
  background-color: #9146ff;
  padding: 10px;
  text-decoration: none;
}
.page-header .pull-right {
  margin-left: 5px;
}
.achievement-header {
  cursor: "pointer";
}
.achievement-header:focus {
  outline: 0;
}
